<template>
    <div class="supplier">
        <el-page-header @back="$router.go(-1)" content="企业信息"></el-page-header>
        <div class="f-w-b">
            <NavigationBar></NavigationBar>
            <div class="main">
                <div class="heard f-c-c"  :style="{ backgroundImage: `url(${backgroundImageUrl})` }">
                    <span>{{ enterpriseName }}</span>
                </div>
                <div class="info f-w-b">
                    <div class="info_item" v-for="(item, index) of enterpriseList" :key="index">
                        <div class="title">{{ item.title }}：</div>
                        <div v-if="item.title == '会员状态'">
                            <div v-if="enterpriseInfo.enterpriseLevel == '0'" class="value f-c">
                                尚未开通会员
                                <img class="ml-20" style="cursor: pointer;" @click="dialogVisible = true" src="@/assets/supply/open.png" alt="open">
                            </div>
                            <div class="value f-c" v-else>
                                <img class="mr-10 h-22 w-90" v-if="enterpriseInfo.enterpriseLevel == '1'" src="@/assets/supply/silver.png" alt="silver3">
                                <img class="mr-10 h-22 w-90" v-else-if="enterpriseInfo.enterpriseLevel == '2'" src="@/assets/supply/gold.png" alt="gold3">
                                <img class="mr-10 h-22 w-90" v-else-if="enterpriseInfo.enterpriseLevel == '3'" src="@/assets/supply/jadeite.png" alt="jadeite3">
                                <img class="mr-10 h-22 w-90" v-else-if="enterpriseInfo.enterpriseLevel == '4'" src="@/assets/supply/diamond.png" alt="diamond3">
                                有效期至：{{ enterpriseInfo.levelEdate }} 
                                <img class="ml-10" style="cursor: pointer;" @click="dialogVisible = true" src="@/assets/supply/renew.png" alt="renew"> 
                            </div>
                        </div>
                        <div class="value" v-else>{{ item.value }}</div>
                    </div>
                    <div class="info_introduction">
                        <span>企业简介：</span>
                        <div class="">{{ introduction }}</div>
                    </div>
                    <!-- <div class="qrcode" ref="qrcode" style="margin:0 auto;"></div> -->
                    <vue-qr v-if="isqcCodeUrl" class="qrcode" :size="120" :logoScale="0.2" :logoSrc="imageSrc" :text="qcCodeUrl"></vue-qr>
                </div>
                <!-- 按钮 -->
                <div class="supplier-btns f-c-a">
                    <router-link v-if="isShowEdit" :to="{
            name: 'EditEnterpriseInfo', query: {
                key: $UrlEncode.encode(JSON.stringify({
                    id: queryId, type: 'modify'
                }))
            }
        }">
                        <el-button class="sign-out-supply edit"><i class="el-icon-edit-outline"></i>编辑</el-button>
                    </router-link>
                    <template v-if="queryType === 'supply'">
                        <el-button class="sign-out-supply other" @click="handleBtn(enterpriseState)">{{
            enterpriseState_text
        }}
                        </el-button>
                    </template>
                    <router-link v-if="enterpriseInfo.state == '020' && enterpriseInfo.dcPermissionFlag == 'Y'" :to="{
            name: 'Transferadmin', query: {
                key: $UrlEncode.encode(JSON.stringify({
                    id: queryId
                }))
                        } }">
                        <el-button class="sign-out-supply other">转让管理员</el-button>
                    </router-link>
                </div>
            </div>
        </div>
        <el-dialog :visible.sync="dialogVisible" width="688px">
            <div class="w--100 f-c-c fs-30 col-33">
                <span >扫码打开识料小程序支付并开通会员</span>
            </div>
            <div class="f-c-c mt-30 mb-60">
                <img src="@/assets/supply/qrcode.png" alt="qrcode">
            </div>
        </el-dialog>
    </div>
</template>
<script>
import { UrlDecodeIdType } from '@/mixins/UrlDecodeMixin';
import { PaginationRetention } from '@/mixins/PaginationRetentionMixin';
import { UnitAccount } from "@/utils/attributeValue1Options";
// import QRCode from 'qrcodejs2';
import VueQr from 'vue-qr';
export default {
    name: 'SupplierInfo',
    mixins: [UrlDecodeIdType, PaginationRetention],
    components: {
        NavigationBar: () => import(/* webpackChunkName: "UploadVideo" */ '../components/NavigationBar.vue'),
        VueQr
    },
    data() {
        return {
            enterPriseImage: '',
            enterpriseName: '',
            enterpriseId: '',
            introduction: '',
            enterpriseList: [],
            tabContainerList: [],
            enterpriseState: '',//企业状态
            enterpriseState_text: '',//企业状态文本
            applyId: 0,
            isShowEdit: false,//是否展示编辑入口
            confirmTitle: '',//确认框标题
            searchValue: '',
            productType: this.$getStorage('productType') ?? 0,
            options: [],
            imageUploadTrends: {
                'position': 'absolute',
                'right': '30px',
                'top': '50px'
            },
            ImageUploadStyle: {
                'width': '80%',
                'left': 0,
                'text-align': 'center',
            },
            showImageUploadBox: false,//展示图片上传盒子
            iSempty: false,
            enterpriseInfo: {},
            text: '',
            qrcode: null,
            qcCodeUrl:'',
            imageSrc:require("@/assets/supply/erweima_logo.png"),
            isqcCodeUrl:false,
            backgroundImageUrl:'',
            dialogVisible:false
        }
    },
    created() {
        this.findEnterpriseAuditStateAndInfo();
        this.findByIdEnterpriseInfo(this.queryId);
        this.$setStorage('EnterpriseRelatedId', this.queryId)
        this.findSearchProductTypeList();
        this.getEnterpriseQcCodeInfo();
    },
    watch: {
        searchValue() {
            if (!this.searchValue) {
                this.getDate();
            }
        }
    },
    methods: {
        getDate() {
            this.openEnterpriseProductList(this.paging, this.pagingSize);
        },
        // 获取产品类型
        async findSearchProductTypeList() {
            let data = {
                page: 1,
                size: 10
            }
            await this.$http.findSearchProductTypeList(data).then(res => {
                if (res.code == 200) {
                    let records = res.data.records,
                        odj = {};
                    records.forEach(item => {
                        odj = {
                            value: item.productTypeId,
                            label: item.productTypeName
                        }
                        this.options.push(odj)
                    });
                }
            })
        },
        // 根据企业id获取企业列表详情接口 
        findByIdEnterpriseInfo(enterpriseId) {
            let that = this, data = {
                enterpriseId: enterpriseId ? enterpriseId : this.$getStorage('enterpriseStateInfo').enterpriseId,
                source: "pc",
                applicantId: parseInt(that.$store.state.userId),
                token: that.$getStorage("token")
            };
            that.$http.findByIdEnterpriseInfo(data).then(res => {
                let { enterpriseName, contact, enterpriseTelephone, contactTelephone, city, address, eimage1, introduction, legalPerson, enterpriseId, enterpriseLevel } = res.data;
                that.enterpriseName = enterpriseName;
                that.enterpriseId = enterpriseId;
                that.introduction = introduction;
                that.enterPriseImage = eimage1;
                that.enterpriseList = [
                    {
                        "title": "法人姓名",
                        "value": legalPerson ?? ''
                    }, {
                        "title": "联系人",
                        "value": contact ?? ''
                    }, {
                        "title": "企业电话",
                        "value": enterpriseTelephone ?? ''
                    }, {
                        "title": "联系电话",
                        "value": contactTelephone ?? ''
                    }, {
                        "title": "所在城市",
                        "value": city ?? ''
                    }, {
                        "title": "详细地址",
                        "value": address ?? ''
                    },{
                        "title": "会员状态",
                        "enterpriseLevel": enterpriseLevel ?? ''
                    }
                ];
            });
        },
        // 首页企业开放商品搜索列表接口
        openEnterpriseProductList(page = this.page, size = this.size) {
            let that = this,
                data = {
                    size,
                    page,
                    productType: that.productType,
                    enterpriseId: that.queryId,
                    productName: that.searchValue,
                    lastModifyUserId: parseInt(that.$store.state.userId),
                    token: that.$getStorage("token")
                };
            that.$http.openEnterpriseProductList(data).then(res => {
                let { records } = res.data;
                records.forEach(item => {
                    item.productTypeStr = UnitAccount.getproductType(item.productType)
                })
                that.tabContainerList = records;
                that.iSempty = res.data.records.length == 0 ? true : false;
                that.total = res.data.total;
                that.currentPage = res.data.current;
            });
        },
        searchRelevantProduct() {
            this.openEnterpriseProductList()
        },
        searchBtn() {
            if (!this.searchValue) {
                return this.$common.message('请输入搜素内容！', 'warning')
            }
            this.openEnterpriseProductList()
        },
        handleBtn(state) {
            if (state == 3) {//申请加入
                this.userApplyJoinEnterprise()
            } else if (state == 1 || state == 5) {//退出企业
                this.quitSupply()
            } else if (state == 6) {//撤销退出
                this.userRevokeJoinEnterprise()
            } else if (state == 2) {//企业解散
                this.disbandEnterprise()
            } else if (state == 4 || state == 7) {//撤销解散  恢复企业状态
                this.revokeEnterpriseAudit()
            }
        },
        // 用户申请退出企业接口
        quitSupply() {
            let that = this;
            that.$confirm(that.confirmTitle, '提示').then(() => {
                that.$http.userApplyQuitEnterprise({
                    enterpriseId: that.queryId,
                    applyUserId: parseInt(that.$store.state.userId),
                    token: that.$getStorage('token')
                }).then(res => {
                    if (res.code == 200) {
                        that.$common.message(res.msg, 'success');
                        // that.$common.DelayedBack(0);
                        that.$router.push('/SupplyIndex')
                    }
                })
            }).catch(() => { })
        },
        // 用户撤销加入企业或用户撤销退出企业接口
        userRevokeJoinEnterprise() {
            let that = this;
            that.$confirm(that.confirmTitle, '提示').then(() => {
                that.$http.userRevokeJoinEnterprise({
                    applyUserId: parseInt(that.$store.state.userId),
                    token: that.$getStorage('token'),
                    applyId: that.applyId
                }).then(res => {
                    if (res.code == 200) {
                        that.$common.message(res.msg, 'success')
                        that.$common.DelayedBack(0);
                    }
                })
            }).catch(() => { })
        },
        // 申请加入企业
        userApplyJoinEnterprise() {
            let that = this;
            that.$confirm(that.confirmTitle, '提示').then(() => {
                that.$http.userApplyJoinEnterprise({
                    enterpriseId: that.queryId,
                    applyUserId: parseInt(that.$store.state.userId),
                    token: that.$getStorage('token')
                }).then(res => {
                    if (res.code == 275) {
                        that.$confirm(res.msg, '提示').then(() => {
                            that.revokePreviousThenApply(res.data)
                        }).catch(() => { })
                    } else {
                        that.$common.message(res.msg, 'success')
                        that.$common.DelayedBack(2000);
                    }

                })
            }).catch(() => { })
        },
        // 撤销上一条申请然后再加入企业
        revokePreviousThenApply(applyId) {
            let that = this;
            that.$http.userRevokeJoinEnterprise({
                applyUserId: parseInt(that.$store.state.userId),
                token: that.$getStorage('token'),
                applyId
            }).then(res => {
                if (res.code == 200) {
                    that.$http.userApplyJoinEnterprise({
                        enterpriseId: that.queryId,
                        applyUserId: parseInt(that.$store.state.userId),
                        token: that.$getStorage('token')
                    }).then(res => {
                        if (res.code == 200) {
                            that.$common.message(res.msg, 'success')
                            that.$common.DelayedBack(2000);
                        }
                    })
                }
            })
        },
        // 企业解散接口
        disbandEnterprise() {
            let that = this;
            that.$confirm(that.confirmTitle, '提示').then(() => {
                that.$http.disbandEnterprise({
                    applicantId: parseInt(that.$store.state.userId),
                    token: that.$getStorage('token')
                }).then(res => {
                    if (res.code == 200) {
                        that.$common.message(res.msg, 'success')
                        that.$common.DelayedBack(2000);
                    }
                })
            }).catch(() => { })

        },
        // 用户撤销企业审核接口
        revokeEnterpriseAudit() {
            let that = this;
            that.$confirm(that.confirmTitle, '提示').then(() => {
                that.$http.revokeEnterpriseAudit({
                    applicantId: parseInt(that.$store.state.userId),
                    token: that.$getStorage('token')
                }).then(res => {
                    if (res.code == 200) {
                        that.$common.message(res.msg, 'success')
                        that.$common.DelayedBack(1500);
                    }
                })
            }).catch(() => { })

        },
        // 跳转去商品详情
        toDetailPage(productId) {
            if (!this.$getStorage('token')) {
                // this.$emit('showLoginBox')
                this.loginBoxVisible = true
            } else {
                this.$router.push({
                    name: 'ProductDetail',
                    query: {
                        key: this.$UrlEncode.encode(JSON.stringify({
                            id: productId,
                            isshowsupply: false,
                        }))
                    }
                })
            }
        },
        // 展开图片上传盒子
        handleImageUploadBox() {
            this.showHistoryBox = false;
            this.showImageUploadBox = true;
        },
        // 关闭上传图片盒子
        closeUploadBox(value) {
            this.showImageUploadBox = value
        },
        //  
        productTypeChange() {
            this.$setStorage('productType', this.productType);
            this.$setStorage('isreload', true);
            this.openEnterpriseProductList();
        },
        // 跳转去色彩库
        goToColourSearch() {
            this.$removeStorage('params');
            this.$removeStorage('csState');
            this.$removeStorage('picker_color');
            this.$removeStorage('picker_colorName');
            this.$router.push({
                name: 'Coloursearch',
                query: {
                    key: this.$UrlEncode.encode(JSON.stringify({
                        enterpriseId: this.enterpriseId,
                    }))
                }
            })
        },
        findEnterpriseAuditStateAndInfo() {
            let that = this;
            that.$http.findEnterpriseAuditStateAndInfo({
                applicantId: parseInt(that.$store.state.userId),
                token: that.$getStorage('token')
            }).then(res => {
                that.enterpriseInfo = res.data;
                if (this.queryType === 'supply') {//点击企业
                    let enterpriseInfo = this.enterpriseInfo,
                        { state, dcPermissionFlag } = enterpriseInfo,
                        { e_i } = enterpriseInfo.userPermission,
                        enterpriseState = '',
                        enterpriseState_text = '',
                        confirmTitle = '';
                    if (state === '020' && e_i === 'W') {
                        enterpriseState = 1
                        enterpriseState_text = '退出企业'
                        confirmTitle = '确定退出该企业？'
                        if (dcPermissionFlag === 'Y') {
                            enterpriseState = 2
                            enterpriseState_text = '解散企业'
                            confirmTitle = '确定解散企业？'
                        }
                    } else if (state === '000') {
                        enterpriseState = 3
                        enterpriseState_text = '申请加入'
                        confirmTitle = '确定加入该企业吗？'
                    } else if (state === '041' && dcPermissionFlag === 'Y') {
                        enterpriseState = 4
                        enterpriseState_text = '撤销解散'
                        confirmTitle = '确定撤销解散申请吗？'
                    } else if ((state === '020' || state === '031' || state === '041' || state === '042') && dcPermissionFlag === 'N') {
                        enterpriseState = 5
                        enterpriseState_text = '退出企业'
                        confirmTitle = '确定退出该企业？'
                    } else if (state === '061') {
                        enterpriseState = 6
                        enterpriseState_text = '撤销退出'
                        confirmTitle = '确定撤销退出？'
                    } else if (state === '042' && dcPermissionFlag === 'Y') {
                        enterpriseState = 7
                        enterpriseState_text = '恢复企业状态'
                        confirmTitle = '确定恢复？'
                    }
                    if (e_i === 'W') {
                        if (state === '041' || state === '042') {
                            this.isShowEdit = false
                        } else {
                            this.isShowEdit = true
                        }
                    }
                    this.enterpriseState = enterpriseState;
                    this.enterpriseState_text = enterpriseState_text
                    this.applyId = enterpriseInfo.auditNumber
                    this.confirmTitle = confirmTitle;
                    switch(enterpriseInfo.enterpriseLevel){
                        case 0:
                            this.backgroundImageUrl  = require('../../../assets/supply/bannerSupplyIndex.png');
                        break;
                        case 1:
                            this.backgroundImageUrl  = require('../../../assets/supply/banner1.png');
                        break;
                        case 2:
                            this.backgroundImageUrl  = require('../../../assets/supply/banner2.png');
                        break;
                        case 3:
                            this.backgroundImageUrl  = require('../../../assets/supply/banner3.png');
                        break;
                        case 4:
                            this.backgroundImageUrl  = require('../../../assets/supply/banner4.png');
                        break;
                    }

                }
            })
        },
        // 获取二维码
        getEnterpriseQcCodeInfo() {
            let that = this;
            that.$http.getEnterpriseQcCodeInfo({
                qcType: 'cq_enterprise'
            }).then(res => {    
                let { qcCodeUrl } = res.data;
                this.qcCodeUrl=qcCodeUrl + `?enterpriseId=${this.enterpriseInfo.enterpriseId}`;
                // console.log(this.qcCodeUrl)
                this.isqcCodeUrl = true;
            })
        },

    },
    beforeDestroy() {
        this.$removeStorage('fetchDataPage')
        this.$removeStorage('fetchDataSize')
    }
}
</script>

<style lang="less" scoped>
.supplier {
    width: 1200px;
    margin: auto;

    .main {
        width: 980px;
        .heard {
            // margin-bottom: 50px;
            padding: 0 20px;
            height: 100px;
            // background: url('../../../assets/supply/bannerSupplyIndex.png') no-repeat;


            span {
                font-weight: 500;
                font-size: 30px;
                color: #FFFFFF;
            }
        }

        .info {
            padding: 30px 0px;
            position: relative;

            .info_item {
                margin-bottom: 15px;
                width: 45%;
                height: 45px;
                padding: 15px 20px;

                .title {
                    color: #999999;
                    font-size: 16px;
                }

                .value {
                    margin-top: 13px;
                    color: #333333;
                    font-size: 16px;
                    font-weight: 400;
                }
            }

            .info_introduction {
                width: 100%;
                margin-top: 40px;
                padding: 15px 20px;

                span {
                    color: #999999;
                    font-size: 16px;

                }

                div {
                    margin-top: 20px;
                    font-size: 16px;
                    font-weight: 400;
                    color: #333333;
                    line-height: 32px;
                }
            }
            .qrcode{
                position: absolute;
                right: 0px;
                top: 20px;
            }
        }
    }

    // .heard {
    //     margin-top: 20px;
    //     margin-bottom: 50px;
    //     padding: 0 20px;
    //     height: 160px;
    //     background: url('../../../assets/bg_supplier.png') no-repeat;

    //     .el-image {
    //         border-radius: 5px;
    //         width: 100px;
    //         height: 100px;
    //         margin-right: 20px;
    //     }

    //     span {
    //         font-size: 36px;
    //         color: #FFFFFF;
    //     }
    // }

    // .info {
    //     padding: 40px 60px;
    //     background: #F5F8F9;

    //     .info_item {
    //         // margin-right: 1%;
    //         margin-bottom: 15px;
    //         width: 45%;
    //         height: 45px;
    //         background: #ffffff;
    //         padding: 15px 20px;

    //         .title {
    //             color: #999999;
    //             font-size: 14px;
    //         }

    //         .value {
    //             margin-top: 15px;
    //             color: #333333;
    //             font-size: 16px;
    //             font-weight: 400;
    //         }
    //     }

    //     .info_introduction {
    //         width: 100%;
    //         margin-top: 40px;
    //         background: #FFFFFF;
    //         padding: 15px 20px;

    //         span {
    //             color: #999999;
    //             font-size: 14px;

    //         }

    //         div {
    //             font-size: 16px;
    //             font-weight: 400;
    //             color: #333333;
    //             line-height: 32px;
    //         }
    //     }
    // }

    .supplier-info-box {
        text-align: center;
        margin: 50px auto 80px;

        .supplier-img {
            width: 30%;
            margin: 40px;
        }

        .supplier-info {
            width: 70%;
            margin: auto;
            flex-wrap: wrap;
            border: 1px solid #999;

            .supplier-item:not(:last-child) {
                border-bottom: 1px solid #999;
            }

            .supplier-item {
                box-sizing: border-box;
                width: 100%;
                line-height: 40px;

                span:first-child {
                    width: 25%;
                }

                span:last-child {
                    text-align: left;
                    padding-left: 20px;
                    border-left: 1px solid #999;
                }
            }

        }
    }

    .supplier-btns {
        width: 70%;
        margin: auto;

        .supplier-relevant {
            font-size: 18px;
            line-height: 40px;
            background: #edebeb;
            // margin: 50px auto 10px;
            // padding-left: 20px;
            color: #000;

        }
    }

    .sign-out-supply {
        width: 160px;
        height: 60px;
        padding: 10px;
        text-align: center;

        font-size: 16px;
        border-radius: 10px;
        margin: 30px 0;

    }

    .edit {
        background: linear-gradient(106deg, #026EF4 0%, #38A8F8 100%);
        color: #ffffff;
    }

    .other {
        background-color: #EFF4FA;
        color: #0363FA;
    }

    .sign-out-supply:hover {
        cursor: pointer;
    }

    .search-input {
        margin: 20px 0 10px;

        button {
            position: absolute;
            right: 0;
            width: 10%;
        }
    }

    .relatedgoods {
        margin-top: 70px;

        .title {
            font-size: 24px;
            font-weight: 500;
            color: #333333;
            line-height: 60px;
            text-align: center;
        }

        .search {
            position: relative;

            .search_select {
                width: 110px;
                position: absolute;
                left: 0;
            }

            .search-icon {
                border-radius: 0;
                position: absolute;
                right: 110px;
                border: none;
            }

            .search-icon1 {
                border-radius: 0;
                position: absolute;
                right: 55px;
                border: none;
            }

            .search-icon2 {
                position: absolute;
                right: 0;
                border-radius: 0 4px 4px 0;
            }

            input {
                background-color: #FFF;
                border-radius: 4px 0 0 4px;
                border: 1px solid #E0E1E3;
                border-right: none !important;
                box-sizing: border-box;
                color: #606266;
                display: inline-block;
                height: 40px;
                line-height: 40px;
                outline: 0;
                padding: 0 50px 0 110px;
                transition: border-color .2s cubic-bezier(.645, .045, .355, 1);
                width: 500px;
            }

            :deep(.el-input__inner) {
                border: 1px solid #E0E1E3;
                border-right: none !important;
                border-radius: 4px 0 0 4px;
            }

        }

        .supplier_box {
            width: 99%;
            margin-top: 40px;

            .supplier_item {
                background: #F6F8FB;
                border-radius: 10px;
                // margin-right: 40px;
                margin-bottom: 20px;
                cursor: pointer;
                position: relative;

                &:hover {
                    transform: translateY(-3px);
                }

                img {
                    width: 205px;
                    height: 186px;
                    border-radius: 10px 10px 0 0;
                }

                .name {
                    width: 205px;
                    margin: 10px 0;
                    font-size: 16px;
                    padding-left: 5px;
                    overflow: hidden;
                    text-overflow: ellipsis;
                    white-space: nowrap;
                    // width: 100%;
                }

                .text {
                    padding: 0 5px 10px 5px;

                    .price {
                        color: #FF4E36;
                        font-size: 14px;
                    }

                    .characters {
                        color: #999999;
                        font-size: 14px;
                    }
                }

                .cornermark {
                    position: absolute;
                    right: 10px;
                    top: 5px;
                    border-radius: 5px;
                    background: #ffffff;
                    text-align: center;
                    height: 14px;
                    line-height: 14px;
                    font-size: 14px;
                    color: #699EF2;
                    padding: 5px;
                }
            }



        }

        .empty {
            padding: 50px 0;

            span {
                margin-top: 20px;
            }
        }
    }
}
</style>